import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import GridLayout from "../../components/GridLayout"
import PageLayout from "../../components/PageLayout"
import GridTextProjectElement from "../../components/GridTextProjectElement"
import { projectNames } from "../../context/consts"
import Seo from "../../components/Seo"


const PuzhenAromaDiffuserPage = () => {

    const imageComponents = [
        <StaticImage key={0} src="./../../images/puzhen/puzhen-aromadiffuser-0.jpg" alt="" layout="fullWidth"></StaticImage>,
        <GridTextProjectElement key={1}  projectName={projectNames.PUZHEN_AROMA_DIFFUSER} />,
        <StaticImage key={2} src="./../../images/puzhen/puzhen-aromadiffuser-1.jpg" alt="" layout="fullWidth"></StaticImage>,
        <StaticImage key={3} src="./../../images/puzhen/puzhen-aromadiffuser-2.jpg" alt="" layout="fullWidth"></StaticImage>,
        <StaticImage key={4} src="./../../images/puzhen/puzhen-aromadiffuser-3.jpg" alt="" layout="fullWidth"></StaticImage>,
        <StaticImage key={5} src="./../../images/puzhen/puzhen-aromadiffuser-4.jpg" alt="" layout="fullWidth"></StaticImage>,
    ]

    return (
        <PageLayout firstHead="puzhen" secondHead="aromadiffuser">
            <GridLayout components={imageComponents}></GridLayout>
            <Seo subTitle="puzhen aromadiffuser" />
        </PageLayout>
    )

}

export default PuzhenAromaDiffuserPage